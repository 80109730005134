.GiftPacks{
    min-height: 100vh;
    background: linear-gradient(359deg, #EC2C91 0%, #EF2167 100%);
    width: 100vw;
    padding-bottom: 180px;
    .giftList{
        display: flex;
        width: 100vw;
        padding: 0 28px;
        box-sizing: border-box;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        top: -133px;
        .item{
            width: 333px;
            height: 333px;
            background: #FFF1E4;
            box-sizing: border-box;
            border-radius: 19px;
            border: 4px solid #FDC274;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 12px;
            .img{
                width: 240px;
                height: 240px;
                object-fit: cover;
                margin-bottom: 18px;
            }
            span{
                font-size: 23px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #A47B00;
                line-height: 33px;
            }
        }
    }
    .board{
        width: 692px;
        height: 131px;
        box-sizing: border-box;
        padding-left: 38px;
        background: #D91D70;
        border-radius: 19px;
        position: relative;
        top: -123px;
        left: 50%;
        margin-left: -346px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 23px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1;
        text-align: left;
        span{
            &:first-child{
                margin-bottom: 24px;
            }
        }
    }
    .giftPacksbg{
        width: 100vw;
        height: 680px;
        background: url('../../assets/giftPacksbg.png') no-repeat;
        background-size: 100%;
    
        .headbox{
            color: #fff;
            position: fixed;
            width: 100vw;
            top: 0;
            padding-top: 30px;
            padding-top: constant(safe-area-inset-top);
            padding-top: env(safe-area-inset-top);
        }
        .head {
            display: flex;
            padding: 20px 30px;
            justify-content: space-between;
            align-items: center;
            .backBox {
                width: 150px;
                position: absolute;
                left: 28px;
                .back {
                    width: 40px;
                    height: 40px;
                    // margin: 0px 0px 0px 15px;
                }
            }
    
    
            .title {
                flex: 1;
                text-align: center;
                font-size: 36px;
                font-weight: 400;
                width: 200px;
             
            }
    
            .text {
                font-size: 32px;
                font-weight: 300;
                padding: 10px 20px;
            }
        }
    }
    .tab{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        position: relative;
        top: -160px;
        width: 692px;
        height: 267px;
        background: #FFF0D3;
        border-radius: 19px;
        border: 4px solid #FDC274;
        overflow: hidden;
        .tabtext{
            height: 75px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #B34312;
            font-size: 29px;
            font-weight: 500;
            span{
                display: block;
                color: #FD0606;
                font-size: 39px;
                line-height: 39px;
                padding: 0 12px;
            }
        }
        .tabbox{
            width: 100%;
            box-sizing: border-box;
            padding: 16px 14px 0 14px;
            background: linear-gradient(90deg, #C052F9 0%, #FF3BB1 100%);
            height: 184px;
            display: flex;
            .tablist{
                width: 165px;
                height: 169px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 15px 15px 0px 0px;
                color: #fff;
                font-size: 30px;
                i{
                    display: block;
                    width: 82px;
                    height: 82px;
                    background: url('../../assets/giftpacktabicon.png');
                    background-size: 100%;
                }
                span{
                    margin-top: 12px;
                    display: block;
                    width: 115px;
                    height: 40px;
                    background: #FF5C45;
                    border-radius: 20px;
                    border: 2px solid #FFFFFF;
                    font-size: 25px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 40px;
                    
                }
                &.acitve{
                    background: #FFF0D3;
                }
            }
        }
    }
    .giftPicksNow{
        position: fixed;
        width: 516px;
        height: 128px;
        left: 50%;
        margin-left: -258px;
        bottom: 50px;
        background: url('../../assets/giftPicksNow.png') no-repeat;
        background-size: 100%;
    }
}