.RichTextControl{
    &.RichTextSize{
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
    }
    padding: 20px;
    .title{
        color: rgb(16, 16, 16);
        font-weight: 900;
        font-weight: 32px !important;
        text-align: center;
    }
    .text{
        margin-top: 40px;
        font-weight: 900;
    }
    p{
        font-size: 30px !important;
        line-height: 45px;
    }
   
}
