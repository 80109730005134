.BroadcastHelp{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url("../../assets/broadcast.png") no-repeat;
    background-size: cover;
    padding: 80px 8% 0 8%;
    box-sizing: border-box;
    .text{
        text-align: center;
        font-size: 35px;
        color: rgba(255, 255, 255, 0.8);
        margin-top: 16px;
        opacity: 0.7;
    }
    .powerBtn{
        background: rgba(0, 255, 255, 1);
        width: 100%;
        text-align: center;
        height: 100px;
        line-height: 100px;
        border-radius: 100px;
        border-radius: 100px;
        font-size: 38px;
        margin-top: 120px;
    }
    .butDisabled{
        width: 100%;
        text-align: center;
        height: 100px;
        line-height: 100px;
        border-radius: 100px;
        border-radius: 100px;
        font-size: 38px;
        margin-top: 120px;
        text-align: center;
        font-size: 35px;
        background: rgba(84, 72, 206, 0.8);
        color: rgba(255, 255, 255, 0.4);
    }
    .userInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;
        .image{
            img{
                width: 180px;
                height: 180px;
                border-radius: 50%;
            }
        }
        span{
            color: #fff;
            font-size: 42px;
            margin-top: 50px;
        }
    }
    .textPng{
        width: 100%;
        img{
            width: 100%;
        }
    }
    .fixbottom{
        width: 100%;
        position: fixed;
        background: rgb(147, 111, 242);
        height: 160px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;
        bottom: 0;
        z-index: 999;
        .left{
            display: flex;
            align-items: center;
            img{
                width: 85px;
                height: 85px;
            }
            >div{
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff;
                span{
                    &:first-child{
                        font-size: 30px;
                    }
                    &:last-child{
                        font-size: 22px;
                    }
                }
            }
        }
        .btn{
            width: 186px;
            padding: 20px 20px;
            border-radius: 60px;
            text-align: center;
            border: none;
            font-size: 22px;
            white-space: nowrap;
            background: rgb(255, 204, 0);
        }
    }
    .notice{
        padding: 3px 0px;
        // display: none;
        position: fixed;
        top: 50%;
        z-index: 2000;
        background: #000;
        opacity: 0.6;
        width: 400px;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        border-radius: 20px;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 60px;
        overflow: hidden;
        transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s,-webkit-transform .3s;
    }
 }