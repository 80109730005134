body :global .adm-auto-center-content{
    font-size: 32px;
    padding: 0 10px;
 
}
.inviteBody {
    min-height: 100vh;
    position: relative;
    background: url('../../assets/invitebg.png') no-repeat;
    background-size: 100%;
    .explainIcon{
        position: absolute;
        top: 270px;
        right: 2px;
        width: 54px;
        height: 188px;
        background: url('../../assets/explainIcon.png') no-repeat;
        background-size: 100%;
    }
    .invitebtn{
        position: absolute;
        top: 810px;
        left: 50%;
        margin-left: -220px;
        width: 440px;
        height: 124px;
        background: url('../../assets/invitebtn.png') no-repeat;
        background-size: 100%;
    }
    .shareBox{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 780px;
        padding-bottom: 80px;
        .shareView{
            box-sizing: border-box;
            width: 700px;
            height: 617px;
            background: #FFFBF1;
            border-radius: 38px;
            padding-top: 88px;
            position: relative;
            &::after{
                display: block;
                width: 468px;
                height: 100px;
                content: '';
                background: url(../../assets/mimebtn.png) no-repeat;
                background-size: 100%;
                position: absolute;
                left: 50%;
                margin-left: -234px;
                top: -26px;
            }
            .userList{
                padding: 58px 24px 0 48px;
                height: 390px;
                box-sizing: border-box;
                overflow: hidden;
                .empty{
                    padding-top: 173px;
                    .text{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-size: 25px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 35px;
                    }   
                }
                .scrollView{
                    height: 100%;
                    overflow-y: scroll;
                }
                .userListItem{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 32px;
                    .left{
                        display: flex;
                        align-items: center;
                        .index{
                            font-size: 38px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 600;
                            color: #FF5B49;
                            width: 52px;
                        }
                        .userImg{
                            width: 77px;
                            height: 77px;
                            border-radius: 50%;
                            margin-right: 27px;
                        }
                        .text{
                            font-size: 23px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #A0A0A0;
                            line-height: 33px;
                            .s1{
                                font-size: 25px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #000000;
                                line-height: 35px;
                            }
                        }
                    }
                    .right{
                        display: flex;
                        align-items: center;
                        font-size: 21px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #000000;
                        .money{
                            font-size: 29px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 600;
                            color: #FF5B49;
                            margin-left: 23px;
                            min-width: 70px;
                            text-align: right;
                            .unline{
                                display: block;
                                width: 38px;
                                float: right;
                                height: 4px;
                                background-color: #FF5B49;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }
            .dataList{
                padding: 0 45px;
                display: flex;
                flex-wrap: wrap;
                font-size: 25px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #000000;
                .item{
                    margin-top: 30px;
                    width: 50%;
                    line-height: 35px;
                }
            }
        }
    }
    .bg {
        min-height: 250px;
   

        .head {
            position: fixed;
            width: 100vw;
            box-sizing: border-box;
            top: 0;
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            color: #fff;
            padding-top: 25PX;
            padding-top: constant(safe-area-inset-top);
            padding-top: env(safe-area-inset-top);
            .backBox {
                width: 150px;
                position: absolute;
                left: 30px;

                .back {
                    width: 48px;
                    height: 48px;
                    // margin: 0px 0px 0px 15px;
                }
            }


            .title {
                font-size: 35px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
             
            }
        }

        .SwiperControl {
            // margin-top: 30px;
            padding: 0 60px;
            padding-top: 30px;
            min-height: 80px;
            .Swiper {

                height: 100px;
            }

            .content {
                border-radius: 40px;
                padding: 5px 0 5px 58px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #FFFFFF;
                // margin: 0px 40px;

                .icon {
                    width: 40px;
                    height: 32px;
                    flex: none;
                }

                .contentBox {
                    display: flex;
                    flex: 1;
                    margin-left: 8px;
                    align-items: center;
                    font-size: 28px;
                    white-space: nowrap;
                
                    .nametxt{
                        white-space: nowrap;
                    }
                    .Text {
                        color: rgb(255, 255, 255);
                        overflow: hidden;
                        font-size: 30px;
                        display: flex;
                        align-items: center;
                 
                    }

                    .Color {
                        overflow: hidden;/*超出部分隐藏*/
                        white-space: nowrap;/*不换行*/
                        text-overflow:ellipsis;/*超出部分文字以...显示*/
                        font-weight: 300;
                        max-width: 200px;
                        display: block;
                        color: rgb(255, 238, 0);

                    }
                }
            }
        }

    }

}