
  .Qixi {
	overflow-x: hidden;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	// background: #25084e;
	flex-direction: column;
	align-items: center;
	.video{
		width: 100vw;
		// min-height: 422px;
		background: #25084e;
		.builtIn{
			width: 100%;
		}
	}
	.content{
		width: 100vw;
		box-sizing: border-box;
		padding-top: 10px;
		min-height: 1665px;
		padding-bottom: 160px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: linear-gradient( 180deg, #BDCDFF 0%, #E491FF 49%, #FF96F7 100%);
		.countDown{
			width: 100vw;
			height: 212px;
			background: url('../../assets/qixiCountDown.png') no-repeat;
        	background-size: 100%;
			position: relative;
			.countDownList{
				display: flex;
				align-items: center;
				position: absolute;
				left: 285px;
				bottom: 50px;
				.item{
					display: flex;
					align-items: flex-end;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 25px;
					color: #212121;
					.count{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 58px;
						height: 77px;
						background: linear-gradient( 180deg, #FFA5ED 0%, #FF41ED 100%);
						border-radius: 10px;
						border: 2px solid #FFCDA2;
						font-weight: 500;
						font-size: 35px;
						color: #FFFFFF;
					}
					.text{
						padding-bottom: 5px;
						padding-left: 6px;
						padding-right: 12px;
					}
				}
			}
		}
		.qixiStrategy{
			margin-top: 23px;
			width: 100vw;
			box-sizing: border-box;
			height: 1140px;
			padding:0 27px;
		}
		.qixiBtn{
			position: fixed;
			bottom: 0;
			margin-top: 38px;
			width: 100vw;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 50px;
			padding-bottom: calc(50px + constant(safe-area-inset-bottom));
			padding-bottom: calc(50px + env(safe-area-inset-bottom));
			.image{
				width: 690px;
				height: 160px;
			}
		}
	}
}