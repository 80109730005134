
.ChatanDshare {
    min-height: 100vh;

    .Swiper {
        min-height: 100vh;
        position: relative;
        overflow: hidden;

        .content {
            min-height: 100vh;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            z-index: 1;

            .Image {
                width: 100%;
                height: 100%;
            }
        }

        .background {
            position: absolute;
            height: 100vh;
            width: 100%;
            top: 0%;
            background: rgba(0, 0, 0, .2);
            z-index: -1;
            opacity: 0.5;
            -webkit-filter: blur(5px);
            filter: blur(15px);
            transform: scale(1.2);
        }
        .video{
            width: 100%;
            height: 100%;
        }
    }

    .btnControl {
        position: fixed;
        right: 16px;
        top: 50%;
        transform: translate(0%, 14%);

        .userImg {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            position: relative;
            padding: 8px;

            .img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }

            .userText {
                position: absolute;
                height: 111px;
                width: 111px;
                top: -4px;
                left: -8px;

                Image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .margin{
            margin-top: 30px;
        }
        .btnItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .likeImg {
                width: 80px;
                height: 80px;
            }

            .likeText {
                font-size: 28px;
                color: #fff;
            }
        }


    }

    .footControl{
        position: fixed;
        bottom: 40px;
        z-index: 99; 
        .nickname{
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff;
            padding: 0 10px; 
        } 
        .content {
            margin-top: 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff;
            padding: 0 20px;
           
            display: flex;
            align-items: center;
        } 
        
        .ip{
            color: #02C5FF;
        }
        .years{
            color: #B18AFF;
        }
        .constellation{
            color: #FF6C1E;
        }
    }

    .headControl{
        position: fixed;
        top: 89px;
        left: 40px;
        display: flex;
        color: #FFCC00;
        align-items: center;
        font-size: 28px;
        background-color: rgba(0,0,0,0.5);
        padding: 4px 30px;
        border-radius: 50px;
        z-index: 99999;
      .ren{
        width: 25px;
        height: 30px;
        margin-right: 10px;
      }  
    }

}