
  .HeaderBeat {
	overflow-x: hidden;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	// background: #25084e;
	font-family: PingFangSC, PingFang SC;
	flex-direction: column;
	align-items: center;
	position: relative;
	background: linear-gradient( 180deg, #6840F5 0%, #D2B2F8 100%);
	.bg{
		position: absolute;
		top: 0;
		width: 100vw;
		height: 1623px;
		background: url('../../assets/headerBeat-bg.png') no-repeat;
		background-size: 100%;
		z-index: 1;
	}
	.rule{
		box-sizing: border-box;
		z-index: 999;
		top: 10px;
		position: fixed;
		width: 52px;
		height: 150px;
		background: #FFFFFF;
		border-radius: 17px 0px 0px 17px;
		right: 0;
		font-weight: 500;
		font-size: 27px;
		color: #7247F4;
		line-height: 29px;
		text-align: right;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-right: 6px;
	}
	.ruleMask{
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.img{
			width: 615px;
			height: 590px;
		}
		.close{
			width: 96px;
			height: 96px;
			background: url('../../assets/sceneclose.png') no-repeat;
			background-size: 100%;
			margin-top: 25px;
		}
	}
	.HeaderBeatView{
		position: absolute;
		top: 0;
		z-index: 99;
		padding-top: 36px;
		.cheats{
			margin-top: 34px;
			width: 692px;
			height: 682px;
			background: url('../../assets/cheats.png') no-repeat;
			background-size: 100%;
			box-sizing: border-box;
			padding: 110px 40px 0 40px;
			.giftList{
				height: 454px;
				overflow-y: scroll;
				.item{
					height: 138px;
					padding: 21px 25px;
					box-sizing: border-box;
					background: #F5EFFF;
					border-radius: 19px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 19px;
					.btn{
						width: 127px;
						height: 63px;
						background: linear-gradient( 90deg, #7890FF 0%, #B269FF 100%);
						border-radius: 36px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-weight: 500;
						line-height: 1;
						font-size: 23px;
						color: #FFFFFF;
					}
					.left{
						display: flex;
						align-items: center;
						height: 94px;
						.img{
							width: 94px;
							height: 94px;
							border-radius: 8px;
						}
						.txt{
							padding-left: 27px;
							display: flex;
							flex-direction: column;
							height: 88px;
							justify-content: space-between;
							.t1{
								font-weight: 500;
								font-size: 27px;
								color: #662FCA;
								line-height: 38px;
							}
							.t2{
								font-weight: 400;
								font-size: 23px;
								color: #A6A6A6;
								line-height: 33px;
							}
						}
					}
				}
			}
			.textview{
				display: flex;
				justify-content: space-between;
				font-weight: 400;
				font-size: 23px;
				color: #9F9F9F;
				margin-bottom: 21px;
				.details{
					display: flex;
					padding-right: 30px;position: relative;
					font-weight: 400;
					font-size: 23px;
					color: #000000;
					&::after{
						position: absolute;
						margin-top: 2px;
						right: 0;
						display: block;
						content: '';
						width: 28px;
						height: 280px;
						background: url('../../assets/righticon.png') no-repeat;
						background-size: 100%;
					}
				}
			}
		}
		.thermometerView{
			position: relative;
			left: 44px;
			margin-top: 30px;
			.thermometerViews{
				overflow: hidden;
				padding-top: 10px;
				box-sizing: border-box;
				height: 160px;
				display: flex;
				align-items: flex-start;
				justify-content: center;
			}
			.icon{
				width: 160px;
				height: 136px;
				background: url('../../assets/headerBeat-scaleleft.png') no-repeat;
				background-size: 100%;
				position: absolute;
				z-index: 199;
				left: -40px;
				top: -18px;
			}
			.tip{
				display: flex;
				position: relative;
				top: -8px;
				.tip1,.tip2{
					position: relative;
					width: 242px;
					height: 198px;
				}
				.tip1{
					left: 120px;
				}
				.tip2{
					left: 158px;
				}
			}
			// .thermometerLeft{
			// 	width: 60px;
			// 	position: relative;
			// 	left: 36px;
			// 	height: 88px;
			// 	border-radius: 88px 0 0 88px;
			// 	background: linear-gradient(#D460F8 0%, #F0A0FF 100%);
			// }
			.thermometer{
				// overflow: hidden;
				width: 572px;
				height: 88px;
				background: linear-gradient(#D460F8 0%, #F0A0FF 100%);
				border-radius: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				&::before{
					display: block;
					content: '';
					width: 553px;
					height: 73px;
					background: linear-gradient( 180deg, #FBE4FF 0%, #F0BEFF 100%);
					box-shadow: inset 0px 0px 2px 0px #7F1999;
					border-radius: 44px;
				}
				.dotView{
					height: 89px;
					width: 553px;
					position: absolute;
					// top: 1px;
					.dotList{
						width: 553px;
						height: 6px;
						&:first-child{
							.dot{
								&:nth-child(6),&:nth-child(10){
									top: 2px !important;
									margin-top: -18px !important;
								}
								&:last-child{
									background: transparent;
								}
								&::after{
									display: block;
									font-weight: 400;
									font-size: 23px;
									color: #FFFFFF;
									text-align: left;
									position: absolute;
								}
								&:first-child::after{	
									content: '0℃';
									bottom: -126px;
									left: -12px;
								}
								&:nth-child(2)::after{
									content: '10℃';
									bottom: -126px;
									left: -18px;
								}
								&:nth-child(3)::after{
									content: '20℃';
									bottom: -126px;
									left: -18px;
								}
								&:nth-child(6)::after{
									content: '50℃';
									bottom: -106px;
									left: -10px;
								}
								&:nth-child(9)::after{
									content: '80℃';
									bottom: -126px;
									left: -18px;
								}
								&:nth-child(11)::after{
									content: '100℃';
									bottom: -163px;
									left: -26px;
								}
							}
						}
						.dot{
							position: absolute;
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background: #9450A2;
							top: 0;
							left: 50%;
							margin-left: -3px;
							&:nth-child(6),&:nth-child(10){
								width: 34px;
								height: 40px;
								background: url('../../assets/scaleMark.png') no-repeat;
								background-size: 100%;
								left: 50%;
								margin-left: -17px;
								top: 50%;
								margin-top: -18px;
							}
							&:last-child{
								// right: -1px;
								top: -39px;
								margin-left: 0px;
							}
						}
					}
					.dotListlast{
						position: absolute;
						top: 82px;
					}
				}
				.scale{
					width: 553px;
					height: 64px;
					margin-top: -33px;
					top: 50%;
					position: absolute;
					margin-left: -553px;
				}
			}
		}
		.content{
			display: flex;
			flex-direction: column;
			align-items: center;
			.topTit{
				// margin-top: 36px;
				width: 436px;
				height: 140px;
				background: url('../../assets/headerbeat-text.png') no-repeat;
				background-size: 100%;
			}
			.topText{
				font-weight: 400;
				font-size: 29px;
				color: #FFFFFF;
				line-height: 40px;
			}
		}
	}
}