.Holiday {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .top{
        width: 100vw;
        height: 590px;
        background: url('../../assets/holidayTop.png') no-repeat;
        background-size: 100%;
        position: relative;
        .btnList{
            width: 100vw;
            padding: 20px 37px;
            box-sizing: border-box;
            position: absolute;
            bottom: 28px;
            height: 120px;
            display: flex;
            justify-content: space-between;
            bottom: 20px;
            >div{
                &.acitve{
                    background: rgba(1, 50, 82, 1);
                    color: #fff;
                }
                width: 212px;
                height: 80px;
                background: rgba(1, 50, 82, 0.1);
                border-radius: 40px;
                font-size: 30px;
                color: #333;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }
        }
    }
    .center{
        width: 100vw;
        padding: 24px;
        box-sizing: border-box;
        background: url('../../assets/holidayCenter.png') no-repeat;
        background-size: 100%;
        background-size: cover;
        position: relative;
        .centerBox{
            width: 100%;
            background: #044067;
            border-radius: 20px;
            box-sizing: border-box;
            padding: 30px 28px;
            .explain{
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                .explainItem{
                    &:last-child{
                        margin-bottom: 150px;
                    }
                    margin-bottom: 26px;
                    .title{
                        font-weight: 500;
                        position: relative;
                        margin-bottom: 14px;
                        display: inline-block;
                        z-index: 99;
                        &::after{
                            width: 100%;
                            height: 10px;
                            border-radius: 4px;
                            display: block;
                            position: absolute;
                            content: '';
                            background: rgba(221, 126, 0, 0.6);
                            bottom: 0;
                            z-index: -2;
                        }
                    }
                    .content{
                        line-height: 40px;
                        .table{
                            border: 1px solid rgba(255, 255, 255, 0.6);
                            .tableitem{
                                &:not(:last-child){
                                    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
                                }
                                display: flex;
                                align-items: center;
                                >div{
                                    font-size: 27px;
                                    padding: 14px 0;
                                    width: 20%;
                                    height: 80px;
                                    &:not(:last-child){
                                        border-right: 1px solid rgba(255, 255, 255, 0.6);
                                    }
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                }
                                .w40{
                                    width: 40%;
                                    height: 40px;
                                }
                                .w60{
                                    width: 60%;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
                .time{
                    margin-bottom: 22px;
                }
            }
            .userTop{
                .countDown{
                    height: 116.667vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 96px;
                        height: 96px;
                        margin-top: 150px;
                    }
                    >div{
                        margin-top: 28px;
                        text-align: center;
                        font-size: 32px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 45px;
                    }
                }
                .userList{
                    &:first-child{
                        margin-bottom: 34px;
                    }
                    &:last-child{
                        margin-bottom: 100px;
                    }
                    display: flex;
                    margin-bottom: 20px;
                    >div{
                        font-size: 28px;
                        color: #fff;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        &:first-child{
                            width: 60px;
                            padding-right: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span{
                                font-size: 32px;
                            }
                            img{
                                width: 44px;
                                height: 44px;
                            }
                        }
                        &:nth-child(2){
                            display: flex;
                            align-items: center;
                            img{
                                width: 88px;
                                height: 88px;
                                border-radius: 50%;
                            }
                            span{
                                font-size: 32px;
                                margin-left: 20px;
                            }
                        }
                        &:nth-child(3){
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
    .bottom{
        width: 100vw;
        height: auto;
        position: fixed;
        bottom: 0;
        z-index: 999;
    }
}