.mian {
    min-height: 100vh;
    width: 100vw;
    background: #ff3600;
    padding-bottom: 50px;
    .safeTop{
        padding-top: 30px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        height: 672px;
        box-sizing: border-box;
        background: url('../../assets/toplistbg.png') no-repeat;
        background-size: 100% auto;
        background-position: top left;
        position: relative;
        .head {
            display: flex;
            padding: 20px 30px;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            position: relative;
            .backBox {
                width: 150px;
                position: absolute;
                left: 28px;
                .back {
                    width: 40px;
                    height: 40px;
                    // margin: 0px 0px 0px 15px;
                }
            }


            .title {
                flex: 1;
                text-align: center;
                font-size: 36px;
                font-weight: 400;
                width: 200px;
            
            }

            .text {
                font-size: 32px;
                font-weight: 300;
                padding: 10px 20px;
            }
        }
    }
    .userImg {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100vw;
        padding: 0 20px;
        box-sizing: border-box;
        padding-top: 90px;
        .userImgItem{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &:nth-child(1){
                display: block;
                content: '';
                width: 214px;
                height: 326px;
                background: url('../../assets/top2.png') no-repeat;
                background-size: 100%;
                .avatarImg{
                    border: 4px solid #7E889E;
                    &::after{
                        display: block;
                        content: '';
                        width: 54px;
                        height: 48px;
                        background: url('../../assets/top2_.png') no-repeat;
                        background-size: 100%;
                        position: absolute;
                        top: -26px;
                        left: 92px;
                    }
                }
            }
            &:nth-child(2){
                display: block;
                content: '';
                width: 252px;
                height: 360px;
                background: url('../../assets/top1.png') no-repeat;
                background-size: 100%;
                .avatarImg{
                    width: 160px;
                    height: 160px;
                    border: 4px solid #FFD700;
                    left: 50%;
                    margin-left: -80px;
                    top: -30px;
                    img{
                        width: 156px;
                        height: 156px;
                        border-radius: 50%;
                        // position: relative;
                        left: -2px;
                    }
                    &::after{
                        display: block;
                        content: '';
                        width: 64px;
                        height: 56px;
                        background: url('../../assets/top1_.png') no-repeat;
                        background-size: 100%;
                        position: absolute;
                        top: -34px;
                        left: 92px;
                    }
                    &::before{
                        display: block;
                        width: 160px;
                        height: 160px;
                        content: '';
                        background: url('../../assets/rt.png') no-repeat;
                        background-size: 100%;
                    }
                }
            }
            &:nth-child(3){
                display: block;
                content: '';
                width: 214px;
                height: 326px;
                background: url('../../assets/top3.png') no-repeat;
                background-size: 100%;
                .avatarImg{
                    border: 4px solid #C27F16;
                    &::after{
                        display: block;
                        content: '';
                        width: 54px;
                        height: 48px;
                        background: url('../../assets/top3_.png') no-repeat;
                        background-size: 100%;
                        position: absolute;
                        top: -26px;
                        left: 92px;
                    }
                }
            }
            .userName{
                margin: 0 auto;
                width: 135px;
                height: 77px;
                font-size: 27px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 38px;
                text-align: center;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* 设置最大显示行数 */
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
            .sign{
                font-size: 27px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                width: 90px;
                position: absolute;
                bottom: 50px;
                left: 50%;
                margin-left: -45px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 60px;
                    height: 52px;
                    margin-right: 8px;
                }
                span{
                    font-size: 38px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #F4FF00;
                }
            }
            .avatarImg{
                width: 138px;
                height: 138px;
                border-radius: 50%;
                background: #f6f6f6;
                position: relative;
                left: 50%;
                margin-left: -69px;
                top: -20px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 136px;
                    height: 136px;
                    border-radius: 50%;
                    position: absolute;
                    z-index: 999;
                }
                &::before{
                    display: block;
                    width: 132px;
                    height: 132px;
                    content: '';
                    background: url('../../assets/rt.png') no-repeat;
                    background-size: 100%;
                }
            }
        }
    }

    .controller {
        box-sizing: border-box;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 692px;
        height: 900px;
        background: #FFF1E4;
        border-radius: 19px;
        border: 4px solid #FFD300;
        &.flex{
            display: flex;
            align-items: center;
            justify-content: center;
            .user_list{
                padding: 0;
            }
        }
        .user_list{
            height: 100%;
            padding: 40px 32px 0 40px;
            box-sizing: border-box;
            overflow-y: scroll;
            .user_list_item{
                position: relative;
                height: 100px;
                display: flex;
                margin-bottom: 60px;
                width: 100%;
                align-items: center;
                .user_list_item_left{
                    display: flex;
                    align-items: center;
                    font-size: 38px;
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 500;
                    color: #000000;
                    .url{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-left: 30px;
                    }
                    .name{
                        margin-left: 18px;
                        font-size: 31px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #000000;
                    }
                }
                .item_right{
                    position: absolute;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 33px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    .bbb{
                        width: 102px;
                        height: 88px;
                        margin-right: 10px;
                    }
                }
                
            }
        }
        .noData{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            >div{
                font-size: 31px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #A47B00;
                line-height: 42px;
            }
            .noDataImg{
                width: 94px;
                height: 94px;
                margin-bottom: 12px;
            }
        }
    }
}