
  .SweetPalace {
	// overflow-x: hidden;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	// background: #25084e;
	// font-family: PingFangSC, PingFang SC;
	// flex-direction: column;
	// align-items: center;
	// background: #FFACCE;
	.sweetBtn{
		width: 686px;
		height: 150px;
		// box-sizing: border-box;
		background: #FFFFFF;
		box-shadow: 0px 4px 29px 0px rgba(0,0,0,0.5);
		border-radius: 75px;
		position: fixed;
		z-index: 999;
		left: 50%;
		margin-left: -343px;
		bottom: 54px;
        bottom: calc(54px + constant(safe-area-inset-bottom));
    	bottom: calc(54px + env(safe-area-inset-bottom));
		display: flex;
		align-items: center;
		justify-content: center;
		// padding: 12px 23px;
		>div{
			width: 311px;
			height: 125px;
			background: #ccc;
			border-radius: 130px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 38px;
			color: #fff;
		}
		.b1{
			margin-right: 18px;
			background: url('../../assets/SweetPalaceTab1un.png') no-repeat;
			background-size: 100%;
		}
		.b2{
			background: url('../../assets/SweetPalaceTab2un.png') no-repeat;
			background-size: 100%;
		}
		.b1active{
			height: 136px;
			position: relative;
			top: -4px;
			background: url('../../assets/SweetPalaceTab1.png') no-repeat;
			background-size: 100%;
		}
		.b2active{
			height: 136px;
			position: relative;
			top: -4px;
			background: url('../../assets/SweetPalaceTab2.png') no-repeat;
			background-size: 100%;
		}
	}
}