.SharePoster {
    width: 100vw;
    height: 100vh;
    position: relative;
    .header{
        padding-top: 30px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        position: fixed;
        top: 0;
        background: white;
        .headerView{
            width: 100vw;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 36px;
            color: #000000;
            position: relative;
            justify-content: center;
            height: 92px;
            .backBox{
                position: absolute;
                left: 30px;
                z-index: 999;
                .back{
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }
    .overlayContent{
        padding: 230px 60px 0 60px;
        min-height: 1120px;
    }
    .shareActiveBtn{
        margin-top: 36px;
        width: 568px;
        height: 140px;
        background: url('../../assets/shareActiveBtn.png');
        background-size: 100%;
        position: relative;
        left: 50%;
        margin-left: -284px;
    }
}