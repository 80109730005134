
  .AnchorRecruitment {
	margin: auto;
    width: 100vw;
	padding-bottom: 27px;
    height: auto;
	font-family: Source Han Sans CN;
	background: #B2C7FC;
	.topimg{
		width: 100vw;
		height: 600px;
		overflow: hidden;
		display: flex;
    	align-items: center;
    	justify-content: center;
		.image_{
			width: 120%;
			margin-top: -150px;
		}
  	}
	.board{
		padding:0 20px 20px;
		.boardView{
			width: 100%;
			border: 1px solid #000000;
			.title{
				height: 38px;
				font-size: 24px;
				padding: 0 100px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #fff;
				border-bottom: 1px solid #000000;
				display: flex;
				align-items: center;
				justify-content: space-around;
				background: #786CFB;
			}
			.inside{
				box-sizing: border-box;
				padding: 20px 20px 40px 20px;
				width: 100%;
				min-height: 100px;
				background: #fff;
				.signView{
					margin-top: 38px;
					.signbtn{
						padding: 35px 21px;
						.btn_{
							text-align: center;
							line-height: 83px;
							height: 83px;
							background: linear-gradient(90deg, #9A63EB, #3575FF);
							border: 1px solid #000000;
							border-radius: 42px;
							font-size: 35px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #fff;
						}
					}
					.txtlist{
						margin-top: 30px;
						padding: 0 36px;
						display: flex;
						justify-content: space-between;
						>div{
							width: 142px;
							height: 51px;
							line-height: 51px;
							font-size: 32px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #FFFFFF;
							background: #000;
							text-align: center;
						}
					}
					.text{
						padding: 0 36px;
						font-size: 32px;
						font-weight: bold;
						color: #000;
						display: flex;
    					flex-direction: column;
						.text_{
							position: relative;
							&:first-child{
								&::before{
									display: block;
									content: '';
									background: #000;
									width: 14px;
									height: 14px;
									position: absolute;
									left: -7px;
									top: -7px;
								}
								&::after{
									display: block;
									content: '';
									background: #000;
									width: 14px;
									height: 14px;
									position: absolute;
									right: -7px;
									top: -7px;
								}
							}
							&:last-child{
								align-self: flex-end;
								position: relative;
								top: -1px;
								&::before{
									display: block;
									content: '';
									background: #000;
									width: 14px;
									height: 14px;
									position: absolute;
									left: -7px;
									bottom: -7px;
								}
								&::after{
									display: block;
									content: '';
									background: #000;
									width: 14px;
									height: 14px;
									position: absolute;
									right: -7px;
									bottom : -7px;
								}
							}
							height: 56px;
							line-height: 56px;
							background: #DAE6FF;
							padding: 0 23px;
							text-align: center;
							border: 1px solid #000000;
							width: fit-content;
						}
					}
				}
				.QRcode{
					display: flex;
					align-items: center;
					padding-left: 10px;
					margin-top: 42px;
					.QRimg{
						width: 167px;
						height: 167px;
						border: 1px solid #000000;
						border-radius: 14px;
						overflow: hidden;
						display: flex;
						align-items: center;
						justify-content: center;
						img{
							width: 101%;
							height: 101%;
						}
					}
					.bText{
						font-size: 28px;
						color: #000;
						font-weight: bold;
						margin-left: 32px;
						>div{
							display: flex;
							align-items: flex-start;
							.bgline{
								position: relative;
								font-size: 30px;
								z-index: 999;
								line-height: 42px;
								&::before{
									display: block;
									content: '';
									width: 100%;
									height: 14px;
									position: absolute;
									background: rgba(255, 255, 153, 1);
									bottom: 8px;
									z-index: -99;
								}
							}
						}
					}
				}
				.insidItem{
					border: 1px solid #000000;
					position: relative;
					border-radius: 17px;
					overflow: hidden;
					margin-bottom: 31px;
					background: #ECF2FF;
					.arrow{
						width: 104px;
						height: 38px;
						position: absolute;
						top: 11px;
						right: 22px;
						img{
							width: 100%;
							height: 100%;
						}
					}
					.insideItemContent{
						padding: 35px 20px 35px 20px;
						.tit{
							padding: 0 15px;
							height: 39px;
							background: #000000;
							line-height: 39px;
							text-align: center;
							font-size: 26px;
							color: #fff;
							font-weight: bold;
							margin-bottom: 17px;
							display: inline-block;
						}
						.text{
							font-size: 26px;
							line-height: 42px;
							color: #000;
							font-weight: bold;
							margin-bottom: 22px;
							padding-right: 15px;
						}
						.ullist{
							list-style: none;
							font-size: 26px;
							line-height: 42px;
							color: #000;
							font-weight: bold;
							li{
								position: relative;
								padding-left: 17px;
								&::before{
									display: block;
									width: 10px;
									height: 10px;
									border-radius: 50%;
									background: #000;
									content: '';
									position: absolute;
									left: 0;
									top: 18px;
								}
							}
						}
					}
					.hint{
						height: 61px;
						display: flex;
						// border-bottom: 1px solid #000000;
						.l{
							font-size: 36px;
							background: #786CFB;
							box-sizing: border-box;
							width: 61px;
							font-weight: bold;
							color: #fff;
							line-height: 61px;
							text-align: center;
							border-bottom: 1px solid #000000;
						}
						.r{
							width: 175px;
							height: 61px;
							background: #000;
							font-size: 38px;
							text-align: center;
							line-height: 61px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
	}
}