
.TopUpRebate {
  width: 100vw;
  min-height: 100vh;
  background: url('../../assets/topupbg.png') no-repeat;
  background-size: 100% auto;
  background-position: top left;
  position: relative;
  .model{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .body{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img{
        width: 670px;
        height: auto;
      }
      .icon{
        width: 70px;
        height: 70px;
        margin-top: 51px;
      }
    }
  }
  .text{
    width: 124px;
    height: 48px;
    background: url('../../assets/ruletext.png') no-repeat;
    background-size: 100%;
    background-position: center;
    position: absolute;
    right: 0;
    top: 43px;
  }
  .bottom {
    position: absolute;
    width: 100vw;
    padding: 0 20px 30px 20px;
    box-sizing: border-box;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    padding-bottom: calc(30px + constant(safe-area-inset-bottom));
    padding-bottom: calc(30px + env(safe-area-inset-bottom));

    .img{
      width: 100%;
    }
    .btn{
      display: block;
      margin-top: 51px;
      width: 620px;
      height: 106px;
      background: url('../../assets/topupbtn.png') no-repeat;
      background-size: 100% auto;
    }
  }
}