html {
    // background: linear-gradient(to right, rgb(255, 243, 99), rgb(255, 230, 62));
 
}

.AtciVity {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: #FF3600;
    background-size: 100% auto;
    background-position: top left;
    padding-bottom: 120px;
    .safeTop{
        width: 100vw;
        position: fixed;
        background: #FE2412;
        top: 0;
        z-index: 999;
        padding-top: 30px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
    }
    .head {
        display: flex;
        padding: 20px 30px;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        position: relative;
        .backBox {
            width: 150px;
            position: absolute;
            left: 28px;
            .back {
                width: 40px;
                height: 40px;
                // margin: 0px 0px 0px 15px;
            }
        }


        .title {
            flex: 1;
            text-align: center;
            font-size: 36px;
            font-weight: 300;
            width: 200px;
         
        }

        .text {
            font-size: 32px;
            font-weight: 300;
            padding: 10px 20px;
        }
    }
    .bottomBtn{
        display: flex;
        justify-content: space-between;
        width: 100vw;
        height: 230px;
        box-sizing: border-box;
        padding: 65px 30px 0 30px;
        position: fixed;
        bottom: 0;
        background: linear-gradient(180deg, rgba(184,29,42,0) 0%, #B81D2A 32%, #B81D2A 100%);
        >div{
            width: 378px;
            height: 121px;
            background: url('../../assets/activitybtn1.png') no-repeat;
            background-size: 100%;
            &:last-child{
                background: url('../../assets/activitybtn2.png') no-repeat;
                background-size: 100%;
            }
        }
    }
    .content{
        .img1{
            width: 100vw;
            min-height: 600px;
            // margin:  0 auto;
        }
        .details{
            width: 690px;
            margin: 54px auto 0 auto;
            box-sizing: border-box;
            padding: 92px 30px 30px 30px;
            min-height: 2324px;
            background: rgba(255, 241, 228, 1);
            border-radius: 20px;
            border: 3px solid #FFFFFF;
            position: relative;
            font-family: PingFangSC-Regular, PingFang SC;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 400;
            font-size: 31px;
            color: #A47B00;
            line-height: 48px;
            .theme{
                font-weight: bold;
                font-size: 38px;
                color: #D86800;
                line-height: 46px;
                margin-bottom: 63px;
            }
            .tit{
                width: 519px;
                height: 62px;
                line-height: 62px;
                text-align: center;
                font-family: PingFangSC-Medium, PingFang SC;
                background: linear-gradient( 90deg, rgba(255,162,137,0) 0%, rgba(255,160,135,0.51) 52%, rgba(255,54,0,0) 100%);
                font-weight: 500;
                font-size: 33px;
                color: #000000;
            }
            .text{
                margin-top: 32px;
                margin-bottom: 44px;
                .high{
                    font-weight: bold;
                    color: #F27304;
                }
            }
            .textleft{
                text-align: left;
                width: 100%;
                .table_{
                    margin-top: 14px;
                    overflow: hidden;
                    width: 675px;
                    position: relative;
                    left: -25px;
                    background: #fff;
                    ul{
                        li{
                            color: #A47B00;
                            font-size: 26px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            // height: 140px;
                            &:first-child{
                                height: 100px;
                                >div{
                                    font-size: 24px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    line-height: 33px;
                                }
                            }
                            &:not(:first-child){
                                height: 273px;
                            }
                            &:not(:last-child){
                                border-bottom: 2px solid;
                                border-image: linear-gradient(90deg, rgba(250, 245, 239, 0), rgba(235, 220, 205, 1), rgba(244, 231, 219, 0)) 2 2;
                            }
                            display: flex;
                            align-items: center;
                            >div{
                                font-size: 25px;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                margin-left: -2px;
                                width: 202px;
                                &:first-child{
                                    width: 144px;
                                    text-align: center;
                                }
                                &:not(:last-child){
                                    border-right: 2px solid rgba(235, 220, 205, 1);
                                }
                                &:last-child{
                                    width: 233px;
                                }
                                &:nth-child(2){
                                    flex: 1;
                                }
                                .w{
                                    width: 262px;
                                    font-weight: 400;
                                }
                                .feat{
                                    margin-top: 8px;
                                    text-align: center;
                                    font-size: 20px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #FF7651;
                                    line-height: 28px;
                                }
                                .img{
                                    width: 176px;
                                    height: 209px;
                                }
                                .unlike{
                                    width: 172px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .phoneimg{
                padding:0 25px;
                display: flex;
                width: 100%;
                box-sizing: border-box;
                justify-content: space-between;
                margin-bottom: 59px;
                .img{
                    width: 100%;
                    height: 100%;
                }
            }
            .titleicon{
                width: 450px;
                height: 98px;
                position: absolute;
                left: 50%;
                margin-left: -199px;
                top: -30px;
                .icon{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .userInfo{
            width: 690px;
            position: relative;
            margin: 35px auto;
            padding: 96px 30px;
            height: 180px;
            box-sizing: border-box;
            padding: 15px 40px 15px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(255, 241, 228, 1);
            border-radius: 20px;
            border: 3px solid #FFFFFF;
            .left{
                display: flex;
                align-items: center;
                .via{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .name{
                    margin-left: 16px;
                    height: 90px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 30px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    font-size: 25px;
                    color: #858585;
                    line-height: 35px;
                    >span:first-child{
                        width: 244px;
                        overflow: hidden; // 溢出隐藏
                        white-space: nowrap; // 强制一行
                        text-overflow: ellipsis; // 文字溢出显示省略号
                    }
                }
            }
            .right{
                display: flex;
                align-items: center;
                .icon{
                    width: 103px;
                    height: 103px;
                }
                .num{
                    margin-left: 7px;
                    font-weight: 500;
                    font-size: 38px;
                    color: #000000;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
            }
        }
    }
}