.TianliaoDownload{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: url("../../assets/download_bg.png") no-repeat;
    background-size: cover;
    .logo{
        position: absolute;
        top: 80px;
        left: 80px;
        width: 100px;
        height: 100px;
        background: url("../../assets/newLogo.png") no-repeat;
        background-size: cover;
    }
    svg{
        height: max-content;
        width: 80%;
        margin-left: 10%;
        margin-top: 300px;
    }
    .slogan{
        width: 100%;
        padding-left: 10%;
        box-sizing: border-box;
        color: #68BBC3;
        margin-top: 80px;
        font-size: 22px;
        span{
            margin-top: 15px;
        }
    }
    .button_box{
        position: relative;
        margin-top: 15%;
        height: 100px;
        padding: 12px;
        box-sizing: border-box; 
        display: flex;
        align-items: center;
        justify-content: center; 
        width: 350px;
        left: 50%;
        margin-left: -175px;
        text-align: center;
        background-color: transparent;
        border: 1px solid;
        border-radius: 100px;
        border-color: #7CAEC9;
        line-height: 100px;
        >div{
            border-radius: 60px;
            font-weight: bold;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #40D5B9, #B38BD8);
            color: #025D72;
            display: flex;
            align-items: center;
            justify-content: center; 
            font-size: 33px;
        }
    }
 }