.HeaderBeatDetails {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon{
        width: 50px;
        height: 50px;
        background: url('../../assets/headerBeatClose.png') no-repeat;
        background-size: 100%;
        position: absolute;
        right: 42px;
        top: 61px;
    }
    .details{
        position: relative;
        width: 692px;
        height: 682px;
        box-sizing: border-box;
        padding: 0 82px 15px 84px;
        background: url('../../assets/headerBeatDetails.png') no-repeat;
        background-size: 100%;
        .empty{
            // margin-top: 36px;
            height: 380px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 29px;
            color: #AAAAAA;
        }
        .list{
            margin-top: 36px;
            height: 440px;
            overflow-y: scroll;
            .item{
                display: flex;
                align-items: center;
                margin-bottom: 23px;
                justify-content: space-between;
                font-weight: 500;
                font-size: 27px;
                color: #662FCA;
                .left{
                    display: flex;
                    align-items: center;
                    .img{
                        width: 94px;
                        height: 94px;
                        border-radius: 6px;
                        margin-right: 60px;
                    }
                }
            }
        }
        .btnList{
            width: 524px;
            height: 79px;
            background: #F3E7FF;
            border-radius: 39px;
            margin-top: 107px;
            position: relative;
            margin-left: 50%;
            left: -262px;
            display: flex;
            align-items: center;
            justify-content: center;
            .item{
                width: 256px;
                height: 68px;
                background: #F3E7FF;
                border-radius: 39px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 27px;
                color: #AAAAAA;
                &.active{
                    color: #FFFFFF;
                    background: linear-gradient( 90deg, #7890FF 0%, #B950FA 100%);
                }
            }
        }
    }
}