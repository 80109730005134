.NewyearList{
    background: #FF3600;
    padding-bottom: 250px;
    .topBg{
        width: 100vw;
        min-height: 600px;
        box-sizing: border-box;
        background: url('../../assets/jifutop.png') no-repeat;
        background-size: 100%;
        
       
        .headbox{
            padding-top: 30px;
            padding-top: constant(safe-area-inset-top);
            padding-top: env(safe-area-inset-top);
            color: #fff;
            position: fixed;
            width: 100vw;
            top: 0;
            z-index: 999;
            &.topWhite{
                background: #FF3600;
            }
        }
        .head {
            display: flex;
            padding: 20px 30px;
            justify-content: space-between;
            align-items: center;
            .backBox {
                width: 150px;
                position: absolute;
                left: 28px;
                .back {
                    width: 40px;
                    height: 40px;
                    // margin: 0px 0px 0px 15px;
                }
            }
    
    
            .title {
                flex: 1;
                text-align: center;
                font-size: 36px;
                font-weight: 400;
                width: 200px;
             
            }
    
            .text {
                font-size: 32px;
                font-weight: 300;
                padding: 10px 20px;
            }
        }
    }
    .bottomBtn{
        display: flex;
        justify-content: space-between;
        width: 100vw;
        height: 130px;
        padding-top: 30px;
        position: fixed;
        bottom: 50px;
        >div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            background: url('../../assets/jifubtn1.png') no-repeat;
            background-size: 100%;
            &:last-child{
                background: url('../../assets/jifubtn2.png') no-repeat;
                background-size: 100%;
            }
        }
    }
    .content{
        width: 100vw;
        box-sizing: border-box;
        padding: 0 30px;
        .box{
            background: #FFF1E4;
            border-radius: 19px;
            border: 4px solid #FFD300;
            margin-top: 50px;
            position: relative;
            &.boxicon{
                display: block;
                &::before{
                    display: block;
                    content: '';
                    width: 468px;
                    height: 102px;
                    background: url('../../assets/jifuboxtitle.png');
                    background-size: 100%;
                    position: absolute;
                    left: 50%;
                    margin-left: -234px;
                    top: -32px;
                }
            }
            &.details{
                padding-top: 105px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 25px;
                justify-content: center;
                .tit{
                    width: 519px;
                    height: 62px;
                    background: linear-gradient(90deg, rgba(255,162,137,0) 0%, rgba(255,160,135,0.51) 52%, rgba(255,54,0,0) 100%);
                    font-size: 33px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    text-align: center;
                    line-height: 62px;
                }
                .text{
                    font-size: 31px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #A47B00;
                    line-height: 42px;
                    margin-top: 12px;
                    margin-bottom: 30px;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 46px;
                    &.textcenter{
                        text-align: center;
                    }
                    .blue{
                        color: #5a60ff;
                    }
                    &.textsm{
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 48px;
                        margin-bottom: 0;
                    }
                }
                .tableBox{
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 16px;
                    margin-bottom: 50px;
                    .table_{
                        width: 100%;
                        background: #fff;
                        .nowrap{
                            white-space: nowrap;
                        }
                        ul{
                            width: 100%;
                            list-style: none;
                            box-sizing: border-box;
                            padding: 22px 18px;
                            padding-bottom: 0;
                            li{
                                list-style: none;
                                display: flex;
                                font-size: 25px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #A47B00;
                                line-height: 48px;
                                &:not(:first-child){
                                    padding-top: 50px;
                                    padding-bottom: 58px;
                                    // height: 4px;
                                    border-bottom: 2px solid;
                                    border-image: linear-gradient(90deg, rgba(250, 245, 239, 0), rgba(235, 220, 205, 1), rgba(244, 231, 219, 0)) 2 2;
                                }
                                &:not(:nth-child(2)){
                                    .img{
                                        width: 186px;
                                        height: 218px;
                                    }
                                }
                                &:last-child{
                                    border-bottom: unset;
                                }
                                >div{
                                    text-align: center;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    &:first-child{
                                        width: 28%;
                                    }
                                    &:nth-child(2){
                                        width: 42%;
                                    }
                                    &:nth-child(3){
                                        width: 30%;
                                    }
                                    .img{
                                        width: 132px;
                                        height: 132px;
                                    }
                                    .red{
                                        width: 136px;
                                        font-size: 21px;
                                        font-family: PingFangSC, PingFang SC;
                                        font-weight: 400;
                                        color: #FF7651;
                                        line-height: 26px;
                                    }
                                }
                            }
                        }
                    }
                }
                .jifugiftbg{
                    width: 558px;
                    height: 272px;
                    margin-bottom: 52px;
                }
            }
            &.userInfo{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 32px 27px;
                .left{
                    display: flex;
                    align-items: center;
                    .via{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    .name{
                        margin-left: 25px;
                        height: 80px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-size: 25px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;
                        line-height: 35px;
                        >span:first-child{
                            font-size: 27px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            color: #000000;
                            line-height: 38px;
                            width: 244px;
                            overflow: hidden; // 溢出隐藏
                            white-space: nowrap; // 强制一行
                            text-overflow: ellipsis; // 文字溢出显示省略号
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: center;
                    .icon{
                        width: 112px;
                        height: 112px;
                    }
                    .num{
                        margin-left: 10px;
                        font-size: 38px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        line-height: 54px;
                    }
                }
            }
        }
    }
}