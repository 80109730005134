.wxtip {
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: block;
    .wxtipIcon2{
        margin: 0 auto;
        width:58vw;
        height: 70vh;
        background: url("../../assets/wxtipphone.png") no-repeat;
        background-size: contain;
    }
    .wxtipIconNewYear{
        margin: 0 auto;
        width:58vw;
        height: 70vh;
        background: url("../../assets/wxtipphonenewYear.png") no-repeat;
        background-size: contain;
    }
    .wxtipIcon3{
        width: 230px;
        height: 230px;
        background: url("../../assets/prompt2.png") no-repeat;
        background-size: cover;
        border-radius: 50%;
        position: fixed;
        z-index: 999;
        top: 750px;
        right: 56px;
        // margin-right: 57px;
        // margin-bottom: -110px;
    }
    .wxTipIcon{
        width:110px;
        height: 130px;
        background: url("../../assets/IndicatingArrow2.png") no-repeat;
        display: block;
        position: absolute;
        right: 20px;
        top: 40px;
    }
    .wxtipTxt{
        color: #FFF;
        margin-top: 80px;
        font-size: 26px;
        margin-bottom: 50px;
        width: 52vw;
        margin-left: 24vw;
        line-height: 58px;
        text-align: left;
    }
}