.ChatrooMshare {
    width: 100%;
    height: 100vh;
    position: relative;


    .bg {
        width: 100%;
        height: 100%;

    }

    .head {
        position: absolute;
        top: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .userImg {
            width: 120px;
            height: 120px;
            border-radius: 100%;
            border: 1px solid #fff;
            padding: 1px;
            background-color: #fff;

            .img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }

        .user_text {
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 300;
            margin-top: 20px;
            color: #fff;

        }

        .user_title {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            // line-height: 10px;
            width: 76%;
            padding: 0 80px;
            margin-top: 30px;
            color: #fff;
        }

        .textMsg {
            color: #fff;
            margin-top: 80px;
            padding: 0 80px;
            width: 76%;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            margin-top: 50px;
            color: #fff;

            .user_msg {
                margin-bottom: 10px;
                width: 100%;
                text-align: left;
                line-height: 51px;
            }
            .user_mt{
                margin-bottom: 10px;
                margin-top: 30px;
                width: 100%;
                text-align: left;
                line-height: 51px;  
            }
        }

    }

    .foot {
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 20px 30px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        margin-top: 94px;

        .gif {
            width: 40px;
            height: 40px;
            margin-right: 5px;
        }

        .footText {
            font-size: 32px;
        }
    }

}