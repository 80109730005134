.Download {
    width: 100vw;
    height: 100vh;

    .Browser {

        .SwiperBox {
            margin-top: 40px;
            background: #fff;
            box-sizing: border-box;
            width: 100%;
            padding-left: 30px;
            overflow: hidden;
            overflow-x: auto;
            white-space: nowrap;

            .SwiperBox_item {
                width: 274px;
                height: 456px;
                margin-right: 10px;
                display: inline-block;
                img{
                    width: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }
        }

        .singleBtn{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 39px;
            .item{
                width: 300px;
                height: 96px;
                background: #037EF0;
                border-radius: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 35px;
                color: #FFFFFF;
                line-height: 1;
                &:last-child{
                    background: #0CB669;
                    margin-left: 39px;
                }
                img{
                    width: 40px;
                    height: 40px;
                    margin-right: 15px;
                }
            }
        }
        .dowbtn {
            margin-top: 70px;
            position: relative;
            left: 50%;
            margin-left: -333px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 666px;
            height: 100px;
            background: rgba(12, 182, 105, 1);
            border-radius: 200px;
            font-size: 36px;
            font-weight: 400;
            color: #fff;

            img {
                width: 34px;
                height: 34px;
                margin-right: 10px;
            }
        }

        .txtBom {
            width: 100%;
            height: 200px;
            background: #fff;
            font-size: 22px;
            font-weight: 400;
            line-height: 200px;
            color: #333333;
            text-align: center;
        }

        .content {
            padding-left: 58px;
            padding-right: 36px;

            .title {
                font-size: 40px;
                font-weight: 400;
                color: #000000;
                position: relative;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 28px;
                    background: rgba(0, 0, 0, 1);
                    position: absolute;
                    left: -18px;
                    top: 50%;
                    margin-top: -14px;
                    border-radius: 200px;
                }
            }

            .text {
                font-size: 30px;
                font-weight: 300;
                margin-top: 30px;
                line-height: 48px;
            }
        }

        .BrowserTop {
            padding: 60px 40px;
            display: flex;
            align-items: center;

            .logo {
                width: 176px;
                height: 176px;
                margin-right: 24px;
            }

            .textList {
                height: 176px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                >:first-child {
                    font-size: 36px;
                    font-weight: 500;
                }

                >:nth-child(2) {
                    font-size: 30px;
                    font-weight: 300;
                    margin-top: 6px;
                    margin-bottom: 10px;
                }

                .spanTip {
                    display: flex;
                    // flex-wrap: wrap;
                    :not(:last-child) {
                        margin-right: 8px;
                    }

                    >span {
                        // width: 120px;
                        padding: 0 8px;
                        height: 40px;
                        background: rgba(241, 241, 241, 0.4);
                        border-radius: 6px;
                        font-size: 22px;
                        line-height: 1;
                        font-weight: 300;
                        color: #434343;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .weixinPage {
        width: 100%;
        height: inherit;
        background-color: #fff;
        background: url("../../assets/invitebg2.png") no-repeat;
        background-size: cover;
        .content{
            .icon{
                padding-top: 320px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 414px;
                }
            }
            .text{
                padding-top: 38px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 29px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                .gold{
                    color: #FFE60A;
                }
            }
            .btnView{
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 375px;
                .btn{
                    width: 556px;
                    height: 102px;
                    background: url("../../assets/sharebtn.png") no-repeat;
                    background-size: 100%;
                }
            }
        }
    }
}
.text_warp{
    margin-top: 50px;
}
.text_info{
    padding: 20px 80px;
    font-size: 32px;
    // margin-top: 0px;
}