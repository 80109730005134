.ChannelDownload{
    display: flex;
    width: 100vw;
    height: 100vh;
    .bg{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background: url("../../assets/start.png") no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .downButton{
        position: absolute;
        z-index: 99;
        width: 75vw;
        background-color: rgba(255, 204, 0, 1);
        border-radius: 90px;
        height: 90px;
        bottom: 12%;
        left: 50%;
        margin-left: -37.5vw;
        text-align: center;
        line-height: 90px;
        color: rgba(99, 43, 199, 1);
        font-size: 40px;
    }
 }