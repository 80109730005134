.GetOpenId{
    display: flex;
    align-items: center;
    font-size: 68px;
    justify-content: center;
	margin-top: 150px;
 }
 .text_{
	 text-align: center;
	 font-size: 22px;
	 margin-top: 150px;
 }