.TianliaoShare{
    width: 100vw;
    min-height: 100vh;
    background: url("../../assets/invitebg2.png") no-repeat;
    background-size: 100%;
    .content{
        .logo{
            padding: 100px 100px 0 100px;
            display: flex;
            align-items: center;
            font-size: 29px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            img{
                width: 86px;
                height: 86px;
                margin-right: 20px;
            }
        }
        .icon{
            padding-top: 192px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 324px;
            }
        }
        .text{
            padding-top: 108px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 29px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            .gold{
                color: #FFE60A;
            }
        }
        .btnView{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 375px;
            .btn{
                width: 556px;
                height: 102px;
                background: url("../../assets/sharebtn.png") no-repeat;
                background-size: 100%;
            }
        }
    }
}