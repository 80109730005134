.RuleControl{
    padding: 20px;
    .title{
        color: rgb(16, 16, 16);
        font-weight: 900;
        font-size: 32px !important;
    }
    .text{
        margin-top: 40px;
        font-weight: 900;
    }
}