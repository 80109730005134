.scene{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .sceneView{
        width: 522px;
        height: 660px;
        background: url('../../assets/scene-bg.png') no-repeat;
        background-size: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .value{
            font-weight: 400;
            font-size: 29px;
            color: #DB398B;
            position: absolute;
            top: 134px;
            left: 320px;
        }
        .sceneContent{
            width: 479px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: -54px;
            .video{
                width: 479px;
                height: 269px;
            }
            .builtIn{
                width: 479px;
                height: 269px;
            }
            .t1{
                margin-top: 21px;
                font-weight: 400;
                font-size: 29px;
                color: #DB398B;
                line-height: 40px;
            }
            .t2{
                font-weight: 500;
                font-size: 44px;
                color: #DB398B;
                line-height: 62px;
            }
        }
        .wingPuzzle{
            width: 512px;
            height: 256px;
            background: url('../../assets/cp-wing.png') no-repeat;
            background-size: 100%;
            position: relative;
            top: -98px;
            display: flex;
            justify-content: center;
            .avatarImgList{
                display: flex;
                position: relative;
                top: 48px;
                .imgView{
                    width: 138px;
                    height: 138px;
                    background: linear-gradient(180deg, rgba(249, 215, 240, 1), rgba(183, 53, 143, 1));
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:first-child{
                        position: relative;
                        left: 14px;
                    }
                    &:last-child{
                        position: relative;
                        left: -10px;
                    }
                    img{
                        width: 122px;
                        height: 122px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .cpHeader{
                    width: 144px;
                    height: 144px;
                    position: relative;
                    background: url('../../assets/cpHeader.png') no-repeat;
                    background-size: 100%;
                    position: absolute;
                    top: 76px;
                    left: 68px;
                }
            }
        }
    }
    .sceneclose{
        width: 96px;
        height: 96px;
        background: url('../../assets/sceneclose.png') no-repeat;
        background-size: 100%;
        margin-top: 25px;
    }
}