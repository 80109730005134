.overlayContent {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 520px;
    padding: 20px 20px;
    // border-radius: 8px;
    transform: translate(-50%, -50%);
    text-align: left;
    .posterView{
        width: 100%;
        height: 982px;
        position: relative;
        .cavasImg{
            width: 100%;
            height: 982px;
            object-fit: contain;
            border-radius: 30px;
        }
        .posterClose{
            position: absolute;
            height: 46px;
            width: 46px;
            top: 27px;
            right: 27px;
        }
    }
    .loading{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 982px;
    }
    .share{
        margin-top: 44px;
        background: url('../../assets//cpsave.png') no-repeat;
        background-size: 100%;
        width: 340px;
        height: 150px;
    }
}