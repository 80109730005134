
  .CpPage {
	overflow-x: hidden;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	// background: #25084e;
	font-family: PingFangSC, PingFang SC;
	flex-direction: column;
	align-items: center;
	background: #FFACCE;
	padding-bottom: 220px;
	.rule{
		box-sizing: border-box;
		z-index: 999;
		top: 10px;
		position: fixed;
		width: 52px;
		height: 150px;
		background: #FFFFFF;
		border-radius: 17px 0px 0px 17px;
		right: 0;
		font-weight: 500;
		font-size: 27px;
		color: #C73772;
		line-height: 29px;
		text-align: right;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-right: 6px;
	}
	.ruleMask{
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.img{
			width: 521px;
			height: 586px;
		}
		.close{
			width: 96px;
			height: 96px;
			background: url('../../assets/sceneclose.png') no-repeat;
			background-size: 100%;
			margin-top: 25px;
		}
	}
	.cpPhotoTop{
		.img{
			min-height: 650px;
		}
	}
	.sessionView{
		width: 100vw;
		min-height: 1550px;
		background: url('../../assets/sessionBg.png') no-repeat;
		background-size: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		top: -42px;
		.sessionList{
			margin-top: 86px;
			padding: 0 48px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			.item{
				width: 306px;
				height: 402px;
				display: flex;
				align-items: center;
				justify-content: center;
				&:first-child{
					background: url('../../assets/cp1unlock.png') no-repeat;
					background-size: 100%;
					.img{
						background: url('../../assets/cpimg1.png') no-repeat;
						background-size: 100%;
					}
				}
				&:nth-child(2){
					background: url('../../assets/cp2unlock.png') no-repeat;
					background-size: 100%;
					.img{
						background: url('../../assets/cpimg2.png') no-repeat;
						background-size: 100%;
					}
				}
				&:last-child{
					background: url('../../assets/cp3unlock.png') no-repeat;
					background-size: 100%;
					margin-top: 29px;
					.img{
						background: url('../../assets/cpimg3.png') no-repeat;
						background-size: 100%;
					}
				}
				.img{
					width: 250px;
					height: 230px;
					position: relative;
					top: -36px;
					border-radius: 16px;
					overflow: hidden;
				}
				.lock{
					position: relative;
					display: flex;
					flex-direction: column;
					width: 250px;
					height: 230px;
					align-items: center;
					justify-content: center;
					.icon{
						position: absolute;
						z-index: 99;
						width: 61px;
						height: 73px;
						background: url('../../assets/cpLock.png') no-repeat;
						background-size: 100%;
						top: 44px;
					}
					.text{
						text-align: center;
						position: absolute;
						z-index: 99;
						font-weight: 400;
						font-size: 19px;
						color: #FFFFFF;
						line-height: 27px;
						top: 136px;
					}
					&::before{
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.7);
						backdrop-filter: blur(10px);
						opacity: 0.8;
						z-index: 2;
					}
				}
			}
		}
		.cpbtnView{
			margin-top: 296px;
			min-height: 158px;
			width: 617px;
			display: flex;
			align-items: center;
			justify-content: center;
			@keyframes scaleanimation {
				0% {
				  transform: scale(1);
				}
				50% {
				  transform: scale(0.9);
				}
				100% {
				  transform: scale(1);
				}
			}
			.cpbtn{
				min-height: 158px;
				width: 617px;
				background: url('../../assets/cpbtn.png') no-repeat;
				background-size: 100%;
				animation: scaleanimation 2s infinite;
			}
		}
		.cpline{
			width: 562px;
			height: 33px;
			background: url('../../assets/cpline.png') no-repeat;
			background-size: 100%;
			margin-top: 36px;
			margin-left: 38px;
			display: flex;
			position: relative;
			.txt{
				position: absolute;
				font-weight: 400;
				font-size: 23px;
				color: #BF4578;
				line-height: 33px;
				left: -64px;
				top: -16px;
			}
			.cplineitem{
				border-radius: 50%;
				margin-left: 28px;
				width: 67px;
				height: 67px;
				position: absolute;
				top: -17px;
				background: #D9B8B8;
				display: flex;
				justify-content: center;
				.num{
					font-weight: 500;
					font-size: 23px;
					color: #D44545;
					position: relative;
					top: 68px;
				}
			}
			.cplineactive{
				width: 67px;
				height: 67px;
				background: url('../../assets/lineActive.png') no-repeat;
				background-size: 100%;
			}
		}
	}
	.parnter{
		width: 100vw;
		height: 467px;
		background: url('../../assets/parnterBG.png') no-repeat;
		background-size: 100%;
		position: relative;
		top: -52px;
		display: flex;
		align-items: center;
		justify-content: center;
		.viewPoster{
			width: 135px;
			height: 58px;
			line-height: 58px;
			padding-left: 21px;
			box-sizing: border-box;
			background: linear-gradient( 180deg, #FF5CA7 0%, #FEABCD 100%);
			border-radius: 29px 0px 0px 29px;
			font-weight: 500;
			font-size: 25px;
			color: #FFFFFF;
			position: absolute;
			right: 38px;
			bottom: 70px;
		}
		.wingView{
			width: 512px;
			height: 258px;
			position: relative;
			background: url('../../assets/wing.png') no-repeat;
			background-size: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.imgList{
				display: flex;
				position: relative;
				left: 6px;
				.img{
					width: 139px;
					height: 139px;
					border-radius: 50%;
					object-fit: cover;
					&:last-child{
						position: relative;
						left: -6px;
					}
				}
			}
		}
		.cpHeader{
			width: 140px;
			height: 140px;
			position: relative;
			background: url('../../assets/cpHeader.png') no-repeat;
			background-size: 100%;
			position: absolute;
			top: 234px;
		}
		.text{
			font-weight: 400;
			font-size: 27px;
			color: #D5719A;
			position: absolute;
			top: 360px;
			text-align: center;
		}
	}
}