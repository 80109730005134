
  .AnchorRecruitment {
	overflow-x: hidden;
	width: 100vw;
	min-height: 100vh;
	padding-bottom: 100px;
	display: flex;
	background: #25084e;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
    .RecruitPoster{
		width: 100vw;
		margin-bottom: 60px;
	}
	.boardList{
		width: 702px;
		// height: 306px;
		padding-bottom: 30px;
		border-radius: 19px;
		background: #fff;
		margin-bottom: 38px;
		overflow: hidden;
		.title{
			padding: 0 17px;
			font-family: SourceHanSansCN, SourceHanSansCN;
			font-weight: bold;
			font-size: 32px;
			color: #fff;
			line-height: 71px;
			height: 71px;
			background: #570AC4;
			border-radius: 19px 0px 19px 0px;
			width: fit-content;
			margin-bottom: 14px;
		}
		.text{
			font-family: SourceHanSansCN, SourceHanSansCN;
			padding: 0 35px;
			font-weight: 400;
			font-size: 27px;
			color: #000000;
			line-height: 44px;
			text-align: left;
			font-style: normal;
		}
	}
	.serviceView{
		padding: 0 40px;
		margin-top: 80px;
		.title{
			font-family: SourceHanSansCN, SourceHanSansCN;
			font-weight: bold;
			font-size: 32px;
			color: #FFFFFF;
			line-height: 1;
			text-align: left;
			margin-bottom: 50px;
			padding-left: 40px;
		}
		.collapse{
			display: flex;
			align-items: center;
			justify-content: center;
			.collapseView{
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 27px;
				margin-top: 20px;
				.icon{
					width: 32px;
					height: 32px;
					background: url('../../assets/collapce_on.png') no-repeat;
					background-size: 100%;
					margin-left: 6px;
					&.iconOn{
						background: url('../../assets/collapse_off.png') no-repeat;
						background-size: 100%;
					}
				}
			}
		}
		.serviceList{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100vw;
			padding: 0 40px;
			box-sizing: border-box;
			height: 220px;
			overflow: hidden;
			&.serviceListOn{
				height: auto;
			}
			.item{
				display: flex;
				width: 131px;
				overflow: hidden;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: 38px;
				&:not(:nth-child(4n)){
					margin-right: 48px;
				}
				.avatarImg{
					width: 131px;
					height: 131px;
					border-radius: 50%;
				}
				.who{
					margin-top: 15px;
					display: flex;
					padding: 0 15px;
					border-radius: 30px;
					align-items: center;
					background: #fff;
					color: #570AC4;
					justify-content: center;
					font-size: 23px;
					height: 46px;
					white-space: nowrap;
					.icon{
						width: 28px;
						height: 28px;
						background: url('../../assets/serviceIcon.png') no-repeat;
						background-size: 100%;
						margin-right: 4px;
					}
				}
			}
		}
	}
}