.ChatanDshare {
    min-height: 100vh;
    .bannerEmptyView{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        .turntableView{
            width: 100vw;
            height: 100vw;
            position: absolute;
            z-index: 99;
            top: 186px;
            display: flex;
            align-items: center;
            justify-content: center;
            .turntable{
                width: 640px;
                height: 640px;
                background: url('../../assets/bannerEmpty.png') no-repeat;
                background-size: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .needle{
                    width: 184px;
                    height: 258px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .musicImgRotate {animation: rotate 20s linear infinite;}  
                    @keyframes rotate {
                        0% {
                            transform: rotateZ(0deg);
                        }
                        100% {
                            transform: rotateZ(360deg);
                        }
                    }

                .turntableimg{
                    box-sizing: border-box;
                    width: 440px;
                    height: 440px;
                    border-radius: 50%;
                    border: 8px solid white;
                }
            }
        }
        .bannerEmpty{
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            width: 100%;
            top: 0%;
            // background: rgba(0, 0, 0, .2);
            z-index: -1;
            opacity: 0.5;
            -webkit-filter: blur(5px);
            filter: blur(15px);
            transform: scale(1.1);
        }
    }
    .PageIndicatorView{
        width: 100vw;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 7.4vw;
    }

    .Swiper {
        min-height: 100vh;
        position: relative;
        overflow: hidden;

        .content {
            min-height: 100vh;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            z-index: 1;
     

            .Image {
                width: 100%;
                height: 100%;
            }
        }

        .background {
            position: absolute;
            height: 100vh;
            width: 100%;
            top: 0%;
            background: rgba(0, 0, 0, .2);
            z-index: -1;
            opacity: 0.5;
            -webkit-filter: blur(5px);
            filter: blur(15px);
            transform: scale(1.2);
        }
        .video{
            width: 100%;
            height: 100%;
        }
    }

    .btnControl {
        position: absolute;
        right: 2.133vw;
        top: 50%;
        z-index: 999;
        transform: translate(0%, -30%);

        .userImg {
            width: 88px;
            height: 88px;
            border-radius: 100%;
            position: relative;
            padding: 8px;

            .img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }

            .userText {
                position: absolute;
                height: 111px;
                width: 111px;
                top: -4px;
                left: -8px;

                Image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .margin{
            margin-top: 30px;
        }
        .btnItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .likeImg {
                width: 72px;
                height: 72px;
            }

            .likeText {
                margin-top: 4px;
                font-size: 24px;
                line-height: 34px;
                color: #fff;
            }
        }


    }
    .footControlView{
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 120px;
        padding-left: 20px;
        .commentList {
            color: #fff;
            padding-bottom: 30px;
           .commentListItem {
                margin-bottom: 20px;
                border-radius: 60px;
                height: 60px;
                background: rgba(0,0,0,0.2);
                display: flex;
                padding: 0 20px;
                align-items: center;
                width: fit-content;
            }
            .commentListItemNone{
                display: none !important;
            }
            .commentImg {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 10px;
            }
    
            .commentText{
                font-size: 24px;
                margin-right: 10px;
                line-height: 1;
            }
            .bg {
                margin-bottom: 20px;
                border-radius: 30px;
                background: linear-gradient(90deg, #56ADFC 0%, #6ED1FD 100%);
                display: flex;
                padding: 5px 10px;
                align-items: center;
                background: rgba(0, 0, 0, 0.5);
                width: auto;
            }
        }
        .footControl{
            .nickname{
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #fff;
                padding: 0 10px; 
                display: flex;
                align-items: center;
                .address_{
                    display: flex;
                    align-items: center;
                    .address{
                        font-size: 26px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        margin-left: 15px;
                    }
                }
            } 
            .content {
                margin-top: 16px;
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #fff;
                padding: 0 10px;
                width: 75%;
                padding-bottom: 30px;
            } 
            
            .map {
                background: rgba(0, 0, 0, 0.08);
                display: flex;
                align-items: center;
                padding: 5px 10px;
                margin-top: 20px;
                position: fixed;
                bottom: 10px;
                width: 100%;
                margin-bottom: 20px;
                // width: 112%;
        
                .mapImg {
                    width: 18px;
                    height: 24px;
                    margin-right: 10px;
                }
        
                .mapText {
                    color: #FFCC00;
                    font-size: 18px;
                }
            }
        }
    }

    .play {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 999;
        transform: translate(-50%, -50%);
        .play_img {
            width: 90px;
            height: 90px;
        }
    }
    

}